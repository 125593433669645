body 
{
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code
{
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a
{
  text-decoration: none;
  color: black;
}

.card
{
  border: 0;
}

.xc-card-body
{
  transition: 1.0s;
  background-color: white;
}

.xc-app-container {
  padding: 60px 15px 35px 15px;
  overflow: auto;
  transition: 1.0s;
}

.xc-route-component-content {
  margin-bottom: 50x;
}

::-webkit-scrollbar {
  display: none;
}

.xc-tech-slider
{
  margin-top: 20%;
}

.xc-accordion-item
{
  transition: 1.0s;
}

.xc-accordion-button
{
  background: linear-gradient(to bottom, #000000 40%, rgb(102, 0, 71) 100%) !important;
  color: white !important;
  transition: 1.0s;
}

.xc-accordion-button:hover
{
  box-shadow: 0 10px 10px 0 rgb(207, 0, 173), 0 6px 20px 0 rgb(207, 0, 173);
}

.xc-contact-social-links
{
    background: linear-gradient(to bottom, #000000 40%, rgb(102, 0, 71) 100%) !important;
    margin-top: -10px;
    transition: 1.0s;
}

.xc-home-tech-section
{
  transition: 1.0s;
}

.xc-home-tech-section:hover
{
  transition: 1.0s;
  box-shadow: 0 10px 10px 0 rgb(207, 0, 173), 0 6px 20px 0 rgb(207, 0, 173);
}

.card-footer
{
  background-color: white;
}

.card-header
{
  background-color: white;
}

.xc-bottom-copyright
{
    padding: 10px;
    color: black !important;
    transition: 1.0s;
}