.xc-nav-header
{
    background: linear-gradient(to bottom, #000000 40%, rgb(102, 0, 71) 100%) !important;
    transition: 1.0s;
}

.xc-nav-header:hover
{
    box-shadow: 0 10px 10px 0 rgb(207, 0, 173), 0 6px 20px 0 rgb(207, 0, 173);
}

.xc-nav-canvas-header
{
    background: linear-gradient(to bottom, #000000 40%, rgb(102, 0, 71) 100%) !important;
    transition: 1.0s;
}

.xc-nav-canvas-header:hover
{
    box-shadow: 0 10px 10px 0 rgb(207, 0, 173), 0 6px 20px 0 rgb(207, 0, 173);
}

.xc-header-canvas
{
    background-color: black !important;
    transition: 1.0s;
}

.xc-nav-canvas-header-link
{
    text-decoration: none;
    color: gray;
    font-size: 11px;
    transition: 1.0s;
}

.xc-nav-canvas-header-link:hover
{
    color: #ff00d4;
}

.xc-header-link
{
    color: white;
}

.xc-header-link:hover
{
    color: #ff00d4;
}

.xc-header-menu
{
    background: linear-gradient(to bottom, #000000 40%, rgb(102, 0, 71) 100%) !important;
    color: white;
}

.xc-header-menu-toggle
{
    color: white;
}

.xc-header-menu-toggle:hover
{
    color: #ff00d4;
}

.xc-header-menu-item:hover
{
    color: #ff00d4;
    background: linear-gradient(to bottom, #000000 40%, rgb(102, 0, 71) 100%) !important;
}

.xc-brand-header
{
    padding: 10px;
    font-weight: bold;
    transition: 1.0s;
}

.xc-brand-header:hover
{
    color: #ff00d4;
}

.xc-header-reward-icon
{
    animation: reward 6s infinite linear;
}

@keyframes reward {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(359deg);
    }
}

.xc-header-reward-value
{
    margin: 0 15px 0 3px;
}