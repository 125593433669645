.xc-layout 
{
    overflow: hidden;
    transition: 1.0s;
}

.btn.btn-primary
{
  color: #ffffff;
  background: linear-gradient(to bottom, #000000 40%, rgb(102, 0, 71) 100%) !important;
  border-color:  rgb(255, 0, 179) !important;
  transition: 1.0s;
}

.btn.btn-primary:hover,
.btn.btn-primary:focus,
.btn.btn-primary:active,
.btn.btn-primary.active
{
  box-shadow: 0 10px 10px 0 rgb(207, 0, 173), 0 6px 20px 0 rgb(207, 0, 173);  
}