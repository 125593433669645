.xc-nav-footer
{
    padding: 10px;
    background: linear-gradient(to bottom, rgb(102, 0, 71) 0%, black 100%) !important;
    color: white;
    transition: 1.0s;
}

.xc-nav-footer-copyright
{
    color: gray;
    background: linear-gradient(to bottom, #000000 40%, rgb(102, 0, 71) 100%) !important;
    cursor: grab;
}

.pt-4 {
    padding-top: 0 !important;
}

.p-3 {
    padding: 0 !important;
}

.m-1 {
    margin: -10px !important;
}

.mb-4 {
    margin-bottom: 0 !important;
}

.xc-footer-social-link
{
    color: white;
    transition: 1.0s;
}

.xc-footer-social-link:hover
{
    color: #ff00d4;
    transition: 1.0s;
}